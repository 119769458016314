import Bowser from 'bowser';

const SUPPORTED_BROWSER_VERSIONS = {
  edge: '>=79',
  firefox: '>=67',
  chrome: '>=73',
  safari: '>=13',
  opera: '>=60',
  yandex: '>=20',
};

const browser = Bowser.getParser(window.navigator.userAgent);
const isValidBrowser = browser.satisfies(SUPPORTED_BROWSER_VERSIONS);

if (!isValidBrowser) {
  const { protocol, host } = window.location;
  window.location.assign(`${protocol}//${host}/not-supported.html`);
}
